.headerSearch {
  height: 3rem;
  width: 24rem;
  position: relative;
  cursor: pointer;

  .input {
    height: 100%;
    width: 98%;
    box-sizing: border-box;
    background-color: #ececec;
    color: #555;
    font-size: 1rem;
    padding: 0 0.875rem 0 3rem;
    border: none;
    border-radius: 30px;

    &::placeholder {
      padding-left: 20px;
      font-size: 1rem;
      font-style: italic;
    }
  }

  .btn_svg {
    height: 100%;
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: all 0.2s;
    width: 3.5rem;
    border: 2px solid transparent;
    box-sizing: border-box;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0);
  }

  .search_container {
    display: none;
    position: absolute;
    width: 100%;
    min-height: 300px;
    left: 0;
    right: 0;
    top: 55px;
    z-index: 999999;
    border-radius: 6px;
    background: #fff;
    box-shadow: 2px 2px 5px #bcbcbc, -2px -2px 5px #ffffff;
    padding: 1rem;
    overflow: scroll;
  }

  .isShow {
    display: block;
  }
}

@media screen and (max-width: 1100px) {
  .headerSearch {
    width: 100%;
    height: 2.5rem;
    padding-left: 1rem;
    .input {
      width: 95%;
      height: 90%;
      padding: 0 1rem 0 2.5rem;

      &::placeholder {
        font-size: 14px;
      }
    }

    .btn_svg {
      width: 2rem;
      margin-left: 1.5rem;
    }
  }
}
